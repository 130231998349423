import React from 'react';
import { HeadTags } from './Helmet';
import NewComp from './NewComp';

function App() {

  return (
    <div>
      <HeadTags />
      {/* <Helmet>
        <title>Explore the largest list of online ESL teaching jobs. Perfect for both new and experienced teachers, our platform allows voting for top schools. Find your ideal teaching role today!</title>
        <meta
          name="description"
          content="Explore the largest list of online ESL teaching jobs. Perfect for both new and experienced teachers, our platform allows voting for top schools. Find your ideal teaching role today!"
        />
        <meta name="keywords" content="Explore the largest list of online ESL teaching jobs. Perfect for both new and experienced teachers, our platform allows voting for top schools. Find your ideal teaching role today!" />
        <meta name="author" content="Frazerh" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <link rel="canonical" href="https://www.onlineenglishteachingjobs.com" />

        <meta property="og:title" content="Explore the largest list of online ESL teaching jobs. Perfect for both new and experienced teachers, our platform allows voting for top schools. Find your ideal teaching role today!" />
        <meta property="og:description" content="Elevate your online presence with our expert React.js web app development services." />
        <meta property="og:url" content="https://www.onlineenglishteachingjobs.com" />
        <meta property="og:image" content="https://teachersxchange.com/wp-content/uploads/2023/11/FEATURED-IMAGE.jpg" />
        <meta property="og:type" content="website" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Explore the largest list of online ESL teaching jobs. Perfect for both new and experienced teachers, our platform allows voting for top schools. Find your ideal teaching role today!" />
        <meta name="twitter:description" content="Elevate your online presence with our expert React.js web app development services." />
        <meta name="twitter:image" content="https://teachersxchange.com/wp-content/uploads/2023/11/FEATURED-IMAGE.jpg" />
      </Helmet> */}
      <img src="https://teachersxchange.com/wp-content/uploads/2023/11/LIST-BANNER.png" style={{ width: "100%", maxHeight: "400px" }} alt="Header" />
      {/* <h1 style={{ textAlign: "center" }}>Online English Teaching Jobs! - The complete list</h1>
      <p>
        Nies pushed to the bottom. This List was created by
        TeachersXchange Limited.
        Discover the most comprehensive directory of Online English Schools currently
        recruiting, thoughtfully compiled to address the frustrations of encountering
        fragmented and obsolete information elsewhere. Powered by advanced AI, our
        dynamic list is refined by community engagement: educators like you determine the
        rankings with your votes, promoting the best institutions and demoting the lesser.
        Brought to you by TeachersXchange Limited, our platform is dedicated to
        empowering teachers with reliable, up-to-date employment opportunities. Join our
        community and elevate the teaching landscape together.
      </p> */}
      <div className='App'>
        <NewComp />
      </div>
    </div>
  );
}

export default App;
