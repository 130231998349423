import Helmet from "react-helmet";

export const HeadTags = () => {
    return (
        <Helmet>
            <title>Online English Teaching Jobs! - The complete list</title>
            <meta name="keywords" content="Explore the largest list of online ESL teaching jobs. Perfect for both new and experienced teachers, our platform allows voting for top schools. Find your ideal teaching role today!" />
            <meta name="author" content="Frazerh" />
            <link rel="canonical" href="https://www.onlineenglishteachingjobs.com" />
            <meta name="viewport" content="initial-scale=1.0, width=device-width" />
            <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
            <meta name="description" key="description" content="Explore the largest list of online ESL teaching jobs. Perfect for both new and experienced teachers, our platform allows voting for top schools. Find your ideal teaching role today!" />
            <meta name="title" key="title" content="Online English Teaching Jobs! - The complete list" />
            <meta property="og:title" key="og:title" content="Online English Teaching Jobs! - The complete list" />
            <meta property="og:locale" key="og:locale" content="en_US" />
            <meta charSet="utf-8" />
            <meta property="og:type" key="og:type" content="website" />
            <meta
                property="og:description"
                key="og:description"
                content="Explore the largest list of online ESL teaching jobs. Perfect for both new and experienced teachers, our platform allows voting for top schools. Find your ideal teaching role today!"
            />
            <meta
                property="og:image"
                key="og:image"
                content="https://teachersxchange.com/wp-content/uploads/2023/11/FEATURED-IMAGE.jpg"
            />
        </Helmet>
    );
};