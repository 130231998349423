import React, { useState } from 'react';
import { Button, Modal } from 'antd';

const Popup = ({ buttonText, popupText, title }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };
    return (
        <>
            <Button type="primary" onClick={showModal}>
                {buttonText}
            </Button>
            <Modal title={title} open={isModalOpen} footer={false} onCancel={handleCancel}>
                <div dangerouslySetInnerHTML={{ __html: popupText }}></div>
            </Modal>
        </>
    );
};
export default Popup;