import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Table, Input, Typography, Tooltip, Select, Button } from 'antd';
import Popup from './Popup';
import { DislikeOutlined, FrownOutlined, LikeOutlined, SmileOutlined, WarningOutlined } from "@ant-design/icons";
const { Search } = Input;
const { Paragraph, Text } = Typography;
const { Option } = Select;

function NewComp() {
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [sortColumns, setSortColumns] = useState([]);
    const [sponsoredArray, setSponsoredArray] = useState([]);
    const [searchText, setSearchText] = useState("");
    const [selectedSortColumn, setSelectedSortColumn] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await axios.get('https://school-list-782159f46102.herokuapp.com/api');
                const { columnNames, rowData, sponsored } = response.data;
                setSponsoredArray(sponsored);
                setSortColumns(columnNames)
                const apiColumns = columnNames.map((columnName, index) => ({
                    title: columnName,
                    dataIndex: `col${index}`, // Create unique dataIndex for each column
                    key: `col${index}`
                }));
                apiColumns[0] = {
                    title: 'Company Name',
                    dataIndex: 'col13',
                    key: 'col13',
                    render: (text, record) => {
                        console.log(record)
                        var str = record?.col8;
                        str = str?.split("-");

                        var str2 = str[1]?.slice(0, 1)?.toUpperCase() + str[1]?.slice(1);
                        var newStr;
                        if (str2) {
                            newStr = str[0] + str2;
                        } else {
                            newStr = str[0]
                        }
                        return (
                            <>
                                <a href={record.col13} target="_blank" rel="noopener noreferrer">
                                    {record.col0}
                                </a>
                                <div style={{ display: "flex", alignItems: "center", gap: "4px", flexWrap: "wrap" }}>
                                    {
                                        record?.col8 &&
                                        <button className={`statusBtn ${newStr.toLowerCase()}`}>
                                            {record?.col8}
                                        </button>
                                    }
                                    {
                                        record?.col15 &&
                                        <Tooltip title={record?.col15}>
                                            <WarningOutlined style={{ color: "red", fontSize: "19px" }} />
                                        </Tooltip>
                                    }
                                </div>
                            </>
                        )
                    },
                };
                apiColumns[1] = {
                    title: 'Pay Min',
                    dataIndex: 'col1',
                    key: 'col1',
                    render: (text, record) => {
                        return (
                            <>
                                <button className="payMin">
                                    <span>$</span><span>{record?.col1}</span>
                                </button>
                            </>
                        )
                    },
                };
                apiColumns[2] = {
                    title: 'Pay Max',
                    dataIndex: 'col2',
                    key: 'col2',
                    render: (text, record) => {
                        return (
                            <>
                                <button className="payMax">
                                    <span>$</span><span>{record?.col2}</span>
                                </button>
                            </>
                        )
                    },
                };
                apiColumns[4] = {
                    title: "Qualifications",
                    dataIndex: 'col4',
                    key: 'col4',
                    render: (text, record) => (
                        <div
                            style={{
                                width: 60
                            }}
                        >
                            {record.col4}
                        </div>
                    ),
                };
                apiColumns[6] = {
                    title: "Student",
                    dataIndex: 'col6',
                    key: 'col6',
                    render: (text, record) => (
                        <div
                            style={{
                                width: 60
                            }}
                        >
                            {record.col6}
                        </div>
                    ),
                };
                apiColumns[7] = {
                    title: "Located",
                    dataIndex: 'col7',
                    key: 'col7',
                    render: (text, record) => (
                        <div
                            style={{
                                width: 60
                            }}
                        >
                            {record.col7}
                        </div>
                    ),
                };
                apiColumns[9] = {
                    title: "Information",
                    dataIndex: 'col9',
                    key: 'col9',
                    render: (text, record) => (
                        <Paragraph
                            ellipsis={
                                // ellipsis
                                {
                                    rows: 3,
                                    expandable: true,
                                    symbol: 'more',
                                }
                            }
                        >
                            {record.col9}
                        </Paragraph >
                    ),
                };
                apiColumns[11] = {
                    title: "What people think",
                    dataIndex: 'col11',
                    key: 'col11',
                    render: (text, record) => (
                        <div style={{ display: "flex", alignItems: "center", gap: "8px", justifyContent: "center" }}>
                            <Tooltip overlayClassName='smileTooltip' title={record?.col11}>
                                <SmileOutlined style={{ fontSize: "23px" }} />
                            </Tooltip>
                            <Tooltip overlayClassName="sadTooltip" title={record?.col12}>
                                <FrownOutlined style={{ fontSize: "23px" }} />
                            </Tooltip>
                        </div>
                    ),
                };
                // Add a new column for the "Profile" button
                // Add a new column for the Vote buttons
                apiColumns.push({
                    title: 'Profile',
                    dataIndex: 'profile',
                    key: 'profile',
                    render: (text, record) => (
                        <a href={record.col13} target="_blank" rel="noopener noreferrer" style={{ color: "white", backgroundColor: "black", padding: "6px", borderRadius: "10px" }}>
                            Profile
                        </a>
                    )
                });
                apiColumns[14] = {
                    title: 'Vote',
                    dataIndex: 'vote',
                    key: 'vote',
                    render: (text, record, index) => (
                        <div style={{ width: "60px", padding: "0px 19px" }}>
                            <div className={`${parseInt(record?.col14) > 0 ? "positive" : "negative"}`}>{record?.col14}</div>
                            <div className='voteColumn'>
                                <button style={{ padding: "4px", fontSize: "10px" }} onClick={() => handleVote(record, 'up', index)}>
                                    <LikeOutlined />
                                </button> <br />
                                <button style={{ padding: "4px", fontSize: "10px", marginTop: "4px" }} className="dislike" onClick={() => handleVote(record, 'down', index)}>
                                    <DislikeOutlined />
                                </button>
                            </div>
                            {/* {record['vote']} */}
                        </div>
                    )
                };
                console.log(apiColumns)
                setColumns(apiColumns?.filter(f => f?.dataIndex !== "col8" && f?.dataIndex !== "col10" && f?.dataIndex !== "col12" && f.title !== "Link" && f?.dataIndex !== "col15"));
                setData(
                    rowData.map((row, rowIndex) =>
                        row.reduce((acc, value, index) => {
                            acc[`col${index}`] = value;
                            acc[`id`] = rowIndex;
                            return acc;
                        }, { vote: 0 }) // Initialize the 'vote' property with 0
                    )
                );
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data: ', error);
                setLoading(false);
            }
        };
        fetchData();
    }, [refresh]);
    // console.log(columns);
    const handleVote = (record, type, indexOfObj) => {
        // console.log(record, type, indexOfObj);
        const getStorage = JSON.parse(localStorage.getItem(record?.id))
        if (getStorage && getStorage?.record?.id === record?.id) {
            console.log("CALLED")
        } else {
            setData((prevData) =>
                prevData.map((row, index) => {
                    if (row.id === record.id) {
                        const updatedRow = { ...row }; // Create a copy of the row
                        if (type === 'up') {
                            updatedRow.col14 = parseInt(row.col14) + 1; // Update col14 for upvote
                        } else if (type === 'down') {
                            updatedRow.col14 = parseInt(row.col14) - 1; // Update col14 for downvote
                        }
                        return updatedRow;
                    }
                    return row;
                })
            );

            localStorage.setItem(record?.id, JSON.stringify({ record, type, indexOfObj }));
        }
    };
    const onSearch = (value) => {
        setSearchText(value);
    }

    useEffect(() => {
        // Create a deep copy of the data array
        const modifiedArray = data.map(obj => {
            // Create a copy of the object to avoid modifying the original data
            const newObj = { ...obj };

            // Remove the last 2 key-value pairs
            delete newObj.id;
            delete newObj.vote;
            const keys = Object.keys(newObj);

            // Create a new object with only values
            const values = keys.map(key => newObj[key]);

            return values;
        });

        const updateData = async () => {
            await axios.put("https://school-list-782159f46102.herokuapp.com/api", { data: modifiedArray }).then(res => {
                if (res.status === 200) {
                    console.log("Data updated successfully");
                } else {
                    console.log("Data update error");
                }
            });
        };

        updateData();

        return () => {
            // Cleanup or additional logic if needed
        };
    }, [data]);


    useEffect(() => {
        if (selectedSortColumn !== null) {
            // Create a deep copy of the data array
            const sortedData = [...data];

            sortedData.sort((a, b) => {
                const dataIndex = `col${selectedSortColumn}`;

                const isNumeric =
                    !isNaN(Number(a[dataIndex])) && !isNaN(Number(b[dataIndex]));
                if (isNumeric) {
                    // Sort numbers numerically
                    return a[dataIndex] - b[dataIndex];
                } else {
                    // Sort text alphabetically
                    return a[dataIndex].localeCompare(b[dataIndex]);
                }
            });

            setData(sortedData);
        }
    }, [selectedSortColumn]);

    return (
        loading ?
            <div style={{ textAlign: "center" }}>
                <img src="https://res.cloudinary.com/saeedahmed/image/upload/v1701464638/loading_spsg9q.gif" alt="Loading" width="40px" />
                <br />
                *Loading over 300 Online Schools into a beautiful single page table. It will be worth it,
                I promise! <span style={{ color: "red" }}>It may take up to 60 Seconds to load the entire table.</span>
            </div>
            :
            <div>
                <div className="sponsored">
                    {
                        sponsoredArray.map((item, index) => {
                            return (
                                item[1]?.includes("https") &&
                                <>
                                    <div className='box' key={index}>
                                        <div>
                                            <img src={item[1]} alt="Sponsor" />
                                        </div>
                                        <div>
                                            <a href={item[3]} target="_blank" rel="noreferrer">{item[2]}</a>
                                        </div>
                                    </div>
                                </>
                            )
                        })
                    }
                </div>

                <header>
                    <div className='search'>
                        <Search
                            allowClear
                            placeholder="Search by company name"
                            onSearch={onSearch}
                            style={{
                                width: "100%",
                                maxWidth: "600px",
                            }}
                        />
                    </div>
                    <Select
                        placeholder="Sort By"
                        style={{
                            width: 120
                        }}
                        onChange={(val) => setSelectedSortColumn(val)}
                    >
                        {
                            sortColumns?.map((col, index) => {
                                return (
                                    <Option key={index} value={index}>{col}</Option>
                                )
                            })
                        }
                    </Select>
                    <div className='popup'>
                        <Popup title="Add a company" buttonText="Add a company" popupText={"<div>To add a new company that you own or work for please email as much information as you have on that company to Founder@TeachersXchange.com with the Subject: Add a Company</div>"} />
                    </div>
                    <div className='popup'>
                        <Popup title="Suggest changes" buttonText="Suggest changes" popupText={"<div>To fix any wrong information, add additional information, add a new company etc. please click on the ‘’Profile’’ button for the company you want changes made to then comment your changes on that company's profile page. <br /> <br /> If clicking the profile directs to the company and not a profile page then please send changes to Founder@TeachersXchange.com with the Subject: Change a Company</div>"} />
                    </div>
                </header>
                <div className='tableCon'>
                    <div className='refresh'>
                        <Button type='primary' onClick={() => setRefresh(true)}>Refresh</Button>
                    </div>
                    <Table
                        pagination={false}
                        dataSource={data?.filter(f => f?.col0?.toLowerCase().includes(searchText?.toLowerCase())).map(item => ({ ...item }))}
                        columns={columns.map((column) => {
                            const dataIndex = column.dataIndex;
                            console.log(dataIndex === "vote")
                            return {
                                ...column,
                                sorter: (a, b) => {
                                    if (dataIndex === "vote") {
                                        // Sort the selected column numerically in descending order by default
                                        return parseInt(a[dataIndex]) - parseInt(b[dataIndex]);
                                    }
                                    // Implement custom sorting logic based on the column's dataIndex
                                    const isNumeric = !isNaN(Number(a[dataIndex])) && !isNaN(Number(b[dataIndex]));
                                    if (isNumeric) {
                                        // Sort numbers numerically
                                        return b[dataIndex] - a[dataIndex];
                                    } else {
                                        // Sort text alphabetically
                                        return a[dataIndex].localeCompare(b[dataIndex]);
                                    }
                                },
                            };
                        })}
                        scroll={{ x: 'max-content' }}
                    />
                </div>
            </div >
    );
}

export default NewComp;
